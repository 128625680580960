/* eslint-disable require-yield */
/* eslint-disable import/no-anonymous-default-export */
import { put, call, takeLeading } from 'redux-saga/effects'
import { toast } from 'react-toastify'

import url from '../../api'
import {
  getAllCasesError,
  getAllCasesSuccess,
  getAllCases,
  getCase,
  getCaseError,
  updateCase,
  updateCaseError,
  updateCaseSuccess,
  getOpenCases,
  getOpenCasesError,
  getOpenCasesSuccess,
  getCaseInfo,
  getCaseSuccess,
  getCaseInfoSuccess
} from '../actions/caseActions'
import { storeAuthToken } from '../actions/authActions'
import { getAuthToken } from './authSaga'

function* getCasesSaga(action: any): Generator {
  const { hospital_id } = action.payload
  let _setVisible = action.payload?.setVisible

  if (!_setVisible) {
    _setVisible = (val: boolean) => val
  }

  try {
    // Retrieve precinct from localStorage
    const precinct = JSON.parse(localStorage.getItem('precinct') || '{}')

    if (!precinct?.precinct_id) {
      return
    }

    const _authToken: any = yield call(getAuthToken)
    yield put(
      storeAuthToken({ token: _authToken.jwtToken, issuer: _authToken.issue })
    )

    const response: any = yield call(
      url.get,
      `/hospitals/${hospital_id}/cases?get_cases=true&precinct_id=${precinct.precinct_id}`
    )

    const { data } = response?.data

    // Process data
    const _data = data.map((item: any, index: number) => {
      if (!item.severity) {
        item.severity = 'low'
      }
      item.key = index
      return item
    })

    yield put(getAllCasesSuccess(_data))
    _setVisible(false)
  } catch (error: Error | any) {
    if (error.toString() === 'Error: Network Error') {
      toast.error('Please check your network connection and try again')
      yield put(getAllCasesError(error))
    } else if (error.response?.data) {
      const {
        data: { message }
      } = error.response
      toast.error(message)
      yield put(getAllCasesError(message))
    } else {
      console.error('Unexpected error:', error)
    }
  }
}

function* getCaseSaga(action: any): Generator {
  try {
    const precinct = JSON.parse(localStorage.getItem('precinct') || '{}')

    if (!precinct?.precinct_id) {
      return
    }

    const _authToken: any = yield call(getAuthToken)
    yield put(
      storeAuthToken({ token: _authToken.jwtToken, issuer: _authToken.issue })
    )
    const { case_id, hospital_id } = action.payload
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const response: any = yield call(
      url.get,
      `/hospitals/${hospital_id}/cases?case_id=${case_id}&get_cases=true&precinct_id=${precinct.precinct_id}`
    )

    const { data } = response?.data
    yield put(getCaseSuccess(data))
  } catch (error: Error | any) {
    if (error.toString() === 'Error: Network Error') {
      toast.error('Please check your network connection and try again')
      yield put(getCaseError(error))
    }

    if (error.response?.data) {
      const {
        data: { message }
      } = error.response
      toast.error(message)
      yield put(getCaseError(message))
    } else {
      // eslint-disable-next-line no-console
      console.log('error', error)
    }
  }
}

function* updateCaseSaga(action: any): Generator {
  try {
    const _authToken: any = yield call(getAuthToken)
    yield put(
      storeAuthToken({ token: _authToken.jwtToken, issuer: _authToken.issue })
    )
    const { hospital_id, caseData, message } = action.payload
    let _setVisible = action.payload?.setVisible
    let _setCaseData = action.payload?.setCaseData
    if (!_setVisible) {
      _setVisible = (val: boolean) => val
    }
    if (!_setCaseData) {
      _setCaseData = (val: any) => val
    }

    const response: any = yield call(
      url.put,
      `/hospitals/${hospital_id}/cases`,
      caseData
    )
    const { data } = response.data
    toast.success(message)
    _setVisible(false)
    _setCaseData({
      notes: '',
      status: '',
      report: '',
      case_id: '',
      id: ''
    })
    yield put(updateCaseSuccess(data))
    yield put(getAllCases({ hospital_id }))
  } catch (error: Error | any) {
    if (error.toString() === 'Error: Network Error') {
      toast.error('Please check your network connection and try again')
      yield put(updateCaseError(error))
    }
    const {
      data: { message }
    } = error.response
    toast.error(message)
    if (error.response.data) {
      const {
        data: { message }
      } = error.response
      toast.error(message)
      yield put(updateCaseError(message))
    } else {
      // eslint-disable-next-line no-console
      console.log('error', error)
    }
  }
}

function* getOpenCasesSaga(): Generator {
  try {
    const _authToken: any = yield call(getAuthToken)
    yield put(
      storeAuthToken({ token: _authToken.jwtToken, issuer: _authToken.issue })
    )
    const response: any = yield call(url.get, '/cases?case_status=open')
    yield put(getOpenCasesSuccess(response.data.data))
  } catch (error: Error | any) {
    if (error.toString() === 'Error: Network Error') {
      toast.error('Please check your network connection and try again')
      yield put(getOpenCasesError())
    }
    const {
      data: { message }
    } = error.response
    toast.error(message)
    if (error.response.data) {
      const {
        data: { message }
      } = error.response
      toast.error(message)
      yield put(getOpenCasesError())
    } else {
      // eslint-disable-next-line no-console
      console.log('error', error)
    }
  }
}
function* getCaseInfoSaga(action: any): Generator {
  try {
    const _authToken: any = yield call(getAuthToken)
    yield put(
      storeAuthToken({ token: _authToken.jwtToken, issuer: _authToken.issue })
    )
    const { case_id } = action.payload
    const response: any = yield call(url.get, `/cases/${case_id}`)
    const { data } = response?.data
    yield put(getCaseInfoSuccess(data))
  } catch (error: Error | any) {
    if (error.toString() === 'Error: Network Error') {
      toast.error('Please check your network connection and try again')
      yield put(getCaseError(error))
    }
    if (error.response?.data) {
      const {
        data: { message }
      } = error.response
      toast.error(message)
      yield put(getCaseError(message))
    } else {
      // eslint-disable-next-line no-console
      console.log('error', error)
    }
  }
}
function* casesSaga() {
  yield takeLeading(getAllCases, getCasesSaga)
  yield takeLeading(getCase, getCaseSaga)
  yield takeLeading(updateCase, updateCaseSaga)
  yield takeLeading(getOpenCases, getOpenCasesSaga)
  yield takeLeading(getCaseInfo, getCaseInfoSaga)
}

export default casesSaga
